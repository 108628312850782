<template>
  <div class="component-wrapper">
    <div class="cta-container">
      <div v-if="!state.hasSigned">
        <div class="text link" @click="$scrollTo('#form')">Jetzt Petition <br class="show-for-small-only">mitzeichnen</div>
        <img class="link" @click="$scrollTo('#form')" src="@/assets/pen_green.png" />
      </div>
      <div v-else>
        <div class="text link" @click="state.modals.shareModal = true">Jetzt Petition <br class="show-for-small-only">teilen</div>
        <img class="link" @click="state.modals.shareModal = true" src="@/assets/share_icon2.png" />
      </div>
    </div>
    <div class="grid-x footer align-middle align-justify">
      <div class="cell small-12 medium-shrink image-cell">
        <img class="logo-img" src="@/assets/logo.png" />
      </div>
      <div class="cell small-12 medium-auto menu-links align-self-middle justify-right">
        <div class="grid-x grid-margin-x align-right align-middle">
          <div class="cell small-12 medium-shrink menu-cell">
            Petitionsbedingungen
          </div>
          <div class="cell small-12 medium-shrink menu-cell">
            Impressum
          </div>
          <div class="cell small-12 medium-shrink menu-cell">
            Datenschutz
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'News',
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state
  }},
  async mounted() {
    if (this.$ls.get('signFormSent', null)) this.state.hasSigned = true
  },
  computed: {
    
  },
  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.link {
  cursor:pointer
}
.component-wrapper {
  width:100%
}
.cta-container {
  padding-top:80px
  padding-bottom:80px
  text-align:center
  background:#F6F6F6
  .text {
    display:inline-block
    font-family: Playfair Display;
    font-style: normal;
    font-weight: bold;
    font-size: 54px;
    line-height: 120%;
    color: #1D1703;
  }
  img {
    margin-left:32px
    width:64px
    height:64px
    vertical-align:bottom
  }
}
.footer {
  padding:32px
  max-width:1440px
  //height:115px
  background: rgba(255,255,255,1)
  width:100%
}
.logo-img {
  width:249px
}
.menu-cell {
  text-align:right
  padding 0 10px
}
.menu-links {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #1D1703;
}

@media screen and (max-width: 640px) {
  .component-wrapper {
    //margin-left:-25px
    //margin-right:-25px
  }
  .cta-container {
    padding-top:64px
    padding-bottom:64px
    .text {
      text-align:left
      font-size: 22px;
      line-height: 130%;
      color: #1D1703;
    }
    img {
      margin-left:32px
      width:48px
      height:48px
      vertical-align:bottom
    }
  }
  .footer {
    padding:32px
    max-width:1440px
    //height:115px
    background: rgba(255,255,255,1)
    width:100%
  }
  .logo-img {
    width:249px
  }
  .image-cell {
    text-align:center
  }
  .menu-cell {
    text-align:center
    padding 0 10px
    margin-top:24px
  }
  .menu-links {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #1D1703;
  }
}
</style>
