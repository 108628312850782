<template>
<form @submit.prevent="sendForm">
  <!--<div class="component-wrapper grid-x align-center" id="petition-form-start">-->
    <transition-group name="fade" mode="out-in" class="component-wrapper grid-x align-center" id="petition-form-start">
    <div class="cell medium-12 large-9" :key="'successmsg'" v-if="signingSuccess">
      <div class="grid-x align-center form">
        <div class="cell medium-9 large-9 align-center">
          <div class="form-title">
            Wir haben Ihnen eine E-Mail geschickt.
          </div>
          <div class="form-text">
            Sie erhalten nun eine E-Mail mit dem Bestätigungslink. Erst wenn Sie den Link angeklickt haben, werden Sie als Mitzeichner erfasst.
          </div>
          <div class="share-btn-container">
            <div class="button afw-btn" @click="state.modals.shareModal = true">Petition teilen<img class="btn-share-icon" src="@/assets/share_icon.png" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cell medium-12 large-9" :key="'pet-form'" v-else>
      <div class="grid-x align-center form">
        <div class="cell medium-9 large-9 align-center">
          <div class="form-title">
            Jetzt Petition zeichnen
          </div>
          <div class="form-text">
            “Ich fordere, dass unabhängige Berater/Vermittler und Verbraucher weiterhin frei vereinbaren können, in welcher Art und Umfang die Beratung erbracht und in welcher Höhe sie vergütet werden soll. Dabei soll es beiden individuell überlassen bleiben, ob dies über ein Honorar oder über eine in das jeweilige Produkt einkalkulierte Vergütung erfolgt.”
          </div>
          <div class="form-subtitle">
            Ihre Daten
          </div>
          <div class="grid-x grid-margin-x align-left form-elements">
            <div class="cell small-12 medium-6 large-4 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Anrede
                </div>
                <select v-model="petition.salutation" placeholder="Anrede">
                  <option value="Herr">Herr</option>
                  <option value="Frau">Frau</option>
                  <option value="Divers">Divers</option>
                  <option value="keine Angabe">keine Angabe</option>
                </select>
              </div>
            </div>
            <div class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Vorname*
                </div>
                <input @blur="$v.petition.firstname.$touch()" name="vorname" type="text" v-model="petition.firstname" />
                <div class="error-msg" v-if="$v.petition.firstname.$dirty && !$v.petition.firstname.required">Dies ist ein Pflichtfeld.</div>
              </div>
            </div>
            <div  class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Nachname*
                </div>
                <input @blur="$v.petition.name.$touch()" name="nachname" type="text" v-model="petition.name"  />
                <div class="error-msg" v-if="$v.petition.name.$dirty && !$v.petition.name.required">Dies ist ein Pflichtfeld.</div>
              </div>
            </div>
            <div  class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Firma / Organisation
                </div>
                <input type="text" name="firma" v-model="petition.organization" />
              </div>
            </div>
            <div  class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Telefonnummer
                </div>
                <input @keyup="petition.tel = petition.tel.replaceAll(' ', '')" name="telefon" @blur="$v.petition.tel.$touch()" type="text" v-model="petition.tel" />
                <div class="error-msg" v-if="$v.petition.tel.$dirty && !$v.petition.tel.telValidation">Bitte geben sie nur Nummern an.</div>
              </div>
            </div>
            <div  class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  E-Mail*
                </div>
                <input @blur="$v.petition.email.$touch()" name="email" type="text" v-model="petition.email"  />
                <div class="error-msg" v-if="$v.petition.email.$dirty && !$v.petition.email.required">Dies ist ein Pflichtfeld.</div>
              </div>
            </div>
            <div  class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Straße u. Hausnummer*
                </div>
                <input @blur="$v.petition.street.$touch()" name="strasse" type="text" v-model="petition.street"  />
                <div class="error-msg" v-if="$v.petition.street.$dirty && !$v.petition.street.required">Dies ist ein Pflichtfeld.</div>
              </div>
            </div>
            <div  class="cell medium-4 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  PLZ*
                </div>
                <input @blur="$v.petition.zip.$touch()" name="plz" type="text" v-model="petition.zip"  />
                <div class="error-msg" v-if="$v.petition.zip.$dirty && !$v.petition.zip.required">Dies ist ein Pflichtfeld.</div>
                <div class="error-msg" v-if="$v.petition.zip.$dirty && !$v.petition.zip.zipValidation">Bitte geben Sie eine echte Postleitzahl ein.</div>
              </div>
            </div>
            <div  class="cell medium-8 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Ort*
                </div>
                <input @blur="$v.petition.city.$touch()" name="ort" type="text" v-model="petition.city"  />
                <div class="error-msg" v-if="$v.petition.city.$dirty && !$v.petition.city.required">Dies ist ein Pflichtfeld.</div>
              </div>
            </div>
            <div class="cell small-12 form-subtitle">
              Sie sind*
            </div>
            <div class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Bitte wählen Sie, in welcher Rolle Sie an der Petition teilnehmen
                </div>
                <select v-model="petition.type">
                  <option value="1">Verbraucher:in</option>
                  <option value="2">Berater:in / Vermittler:in</option>
                </select>
              </div>
            </div>
            <transition name="fade">
            <div class="cell small-12 input-wrapper" v-if="petition.type==2">
              <div class="input-bg-color">
                <div class="input-title">
                  Berufsbezeichnung*
                </div>
                <select v-model="petition.jobdescription">
                  <option value="Versicherungsvertreter">Versicherungsvertreter:in</option>
                  <option value="Mehrfachagent">Mehrfachagent:in</option>
                  <option value="Makler">Makler:in</option>
                  <option value="Mitarbeiter in einem Versicherungsunternehmen">Mitarbeiter:in in einem Versicherungsunternehmen</option>
                  <option value="Sonstiges">Sonstiges</option>
                </select>
                <div class="error-msg" v-if="$v.petition.jobdescription.$dirty && !$v.petition.jobdescription.required">Dies ist ein Pflichtfeld.</div>
              </div>
            </div>
            </transition>
            <transition name="fade">
            <div class="cell small-12 input-wrapper" v-if="petition.type==2">
              <div class="input-bg-color">
                <div class="input-title">
                  Verbandsmitgliedschaft*
                </div>
                <select v-model="petition.associationmembership">
                  <option value="AfW">AfW - Bundesverband Finanzdienstleistung e.V.</option>
                  <option value="IGVM">IGVM - Interessengemeinschaft Deutscher Versicherungsmakler e.V.</option>
                  <option value="BVK">BVK - Bundesverband Deutscher Versicherungskaufleute e.V.</option>
                  <option value="BDVM">BDVM - Bundesverband Deutscher Versicherungsmakler e.V.</option>
                  <option value="Sonstiges">Sonstiges</option>
                  <option value="keine">keine Verbandsmitgliedschaft</option>
                </select>
                <div class="error-msg" v-if="$v.petition.associationmembership.$dirty && !$v.petition.associationmembership.required">Dies ist ein Pflichtfeld.</div>
              </div>
            </div>
            </transition>
            <transition name="fade">
            <div class="cell small-12 input-wrapper" v-if="petition.type==2">
              <div class="input-bg-color">
                <div class="input-title">
                  Registrierte:r Vermittler:in (IHK) gemäß
                </div>
                <div class="grid-x grid-margin-x grid-margin-y margin-top-1">
                  <div class="cell shrink">
                    <input id="34d" v-model="petition.p_34d" value="true" type="checkbox">
                    <label for="34d">§ 34d GewO</label>
                  </div>
                  <div class="cell shrink">
                    <input id="34e" v-model="petition.p_34e" value="true" type="checkbox">
                    <label for="34e">§ 34e GewO</label>
                  </div>
                  <div class="cell shrink">
                    <input id="34f" v-model="petition.p_34f" value="true" type="checkbox">
                    <label for="34f">§ 34f GewO</label>
                  </div>
                  <div class="cell shrink">
                    <input id="34h" v-model="petition.p_34h" value="true" type="checkbox">
                    <label for="34h">§ 34h GewO</label>
                  </div>
                  <div class="cell shrink">
                    <input id="34i" v-model="petition.p_34i" value="true" type="checkbox">
                    <label for="34i">§ 34i GewO</label>
                  </div>
                </div>
              </div>
            </div>
            </transition>
            <div class="cell small-12 form-subtitle">
              Bitte teilen Sie uns noch Ihr Statement mit, warum Sie diese Petition unterstützen und warum Sie die Teilnahme weiterempfehlen.
            </div>
            <div class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Benutzername – nur für ihr Statement
                </div>
                <input @blur="$v.petition.username.$touch()" name="username" type="text" v-model="petition.username"  />
              </div>
            </div>
            <div class="cell small-12 input-wrapper">
              <div class="input-bg-color">
                <div class="input-title">
                  Statement – max. 280 Zeichen
                </div>
                <textarea v-model="petition.statement" maxlength="280"></textarea>
              </div>
            </div>
            <div  class="cell small-12 input-wrapper white">
              <div class="input-bg-color">
                <label class="b-contain">
                  <span>Mein Benutzername und mein Statement dürfen veröffentlicht werden.</span>
                  <input v-model="petition.public_allowed" value="true" type="checkbox">
                  <div class="b-input"></div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-x align-center form blue">
        <div class="cell medium-9 align-center">
          <div class="grid-x grid-margin-x align-left">
            <div class="cell small-12">
              <div  class="cell small-12 input-wrapper white">
                <div class="input-bg-color">
                  <label class="b-contain">
                    <span>Ja, ich unterstütze die Petition und stimme den Petitionsbedingungen zu.</span>
                    <input @blur="$v.petition.agreePolicy.$touch()" v-model="petition.agreePolicy" value="true" type="checkbox">
                    <div class="b-input"></div>
                  </label>
                  <div class="error-msg-on-blue" v-if="$v.petition.agreePolicy.$dirty && !$v.petition.agreePolicy.sameAs">Ohne die Zustimmung ist die Mitzeichnung nicht möglich.</div>
                </div>
              </div>
              <div class="cell small-12 input-wrapper white">
                <div class="input-bg-color">
                  <label class="b-contain">
                    <span>Ja, ich erteile dem Bundesverband AfW die Erlaubnis zur Information und Kontaktaufnahme.</span>
                    <input v-model="petition.contact_allowed" value="true" type="checkbox">
                    <div class="b-input"></div>
                  </label>
                </div>
              </div>
              <div class="cell small-12 input-wrapper white">
                <div class="input-bg-color">
                  <label class="b-contain">
                    <span>Ja, ich stimme den Datenschutzbestimmungen zu.</span>
                    <input @blur="$v.petition.agreePrivacy.$touch()" v-model="petition.agreePrivacy" value="true" type="checkbox">
                    <div class="b-input"></div>
                  </label>
                  <div class="error-msg-on-blue" v-if="$v.petition.agreePrivacy.$dirty && !$v.petition.agreePrivacy.sameAs">Ohne die Zustimmung ist die Mitzeichnung nicht möglich.</div>
                </div>
              </div>
              <div class="cell small-12 btn-container">
                <div class="input-bg-color" v-if="!signinginProgress">
                  <div :disabled="$v.petition.$invalid && 1==2" @click="sendForm()" class="button afw-btn full-width">Mitzeichnen<img class="btn-pen-icon" src="@/assets/icon_pen.png" /></div>
                </div>
                <div v-else class="loader2">
                  <loader />
                </div>
              </div>
              <div class="cell small-12 star-field">
                * Pflichtfeld
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </transition-group>
  <!--</div>-->
  </form>
</template>

<script>
import { required, requiredIf, email, sameAs, maxLength } from 'vuelidate/lib/validators'
import loader from '@/components/loader.vue'

const zipValidation = (value) => {
  return value > 1000 && value < 99999
}

const telValidation = (value) => {
  if (!value) return true
  console.log("tel", value)
  if (typeof value != "string") return false // we only process strings!  
  return !isNaN(value) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(value)) // ...and ensure strings of whitespace fail
} 

export default {
  name: 'Recipients',
  components: {
    loader
  },
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    signinginProgress: false,
    signingSuccess: false,
    petition: {
      firstname: '',
      name: '',
      salutation: '',
      organization: '',
      tel: '',
      email: '',
      street: '',
      zip: '',
      city: '',
      type: 1,
      jobdescription: '',
      associationmembership: '',
      p_34d: false,
      p_34e: false,
      p_34f: false,
      p_34h: false,
      p_34i: false,
      username: '',
      statement: '',
      public_allowed: false,
      agreePolicy: false,
      contact_allowed: false,
      agreePrivacy:false
    }
  }},
  mounted() {
    //this.getData()
  },
  validations: {
    petition: {
      firstname: {
        required
      },
      name: {
        required
      },
      email: {
        required,
        email
      },
      /*
      emailRepeat: {
        sameAsPassword: sameAs('email')
      },
      */
      street: {
        required
      },
      tel: {
        telValidation
      },
      zip: {
        required,
        zipValidation
      },
      city: {
        required
      },
      jobdescription: {
        required: requiredIf((a) => {
          return a.type == 2
        })
      },
      associationmembership: {
        required: requiredIf((a) => {
          return a.type == 2
        })
      },
      username: {
      },
      statement: {
        maxLength: maxLength(100)
      },
      agreePolicy: {
        sameAs: sameAs( () => true ) 
      },
      agreePrivacy: {
        sameAs: sameAs( () => true ) 
      }
    }
  },
  computed: {
    
  },
  methods:{
    async sendForm() {
      if (this.$v.petition.$invalid) {
        console.log(this.$v.petition.$touch())
        this.$v.petition
        this.$swal.fire(
          'Formular unvollständig',
          'Bitte überprüfen Sie, ob alle Felder vollständig ausgefüllt sind.',
          'warning'
        )
        return false
      } 
      this.signinginProgress = true
      await this.$sleep(1500)
      try {
        let result = await this.$http.post(this.state.apiUrl + '/items/signatory', this.petition)
        this.signingSuccess = true
        this.$ls.set('signFormSent', true, 60 * 60 * 1000 * 24 * 7) //7 days
        this.state.hasSigned = true
        await this.$sleep(300)
        this.$scrollTo('#petition-form-start')
      } catch (error) {
        let err = this.$store.errorHandler(error)
        if (err == 'Field "email" has to be unique.') {
          err = "Diese E-Mail-Adresse existiert bereits."
        }
        this.$swal.fire(
          'Fehler (schade)!',
          'Es ist ein Fehler aufgetreten. Die Rückmeldung vom Server lautet: ' + err,
          'error'
        )
      } finally {
        this.signinginProgress = false
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.component-wrapper {
  transition: all 1s ease
  max-height:3000px
  filter: drop-shadow(32px 32px 64px rgba(0, 0, 0, 0.1));
}
.b-contain {
  span {
    display:block
  }
}
.form {
  background:white
  padding: 64px 0px
  &.blue {
    background: #00305D
    .b-contain {
      span {
        color:white!important
      }
    }
    
  }
}
.form-subtitle {
  margin-top:64px
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  color: #00305D;
}
.form-elements {
  margin-top:24px
}
.form-title {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  color: #1D1703;
}
.form-text {
  font-size: 18px;
  line-height: 150%;
  color: #777777;
  margin-top:16px
}
.star-field {
  font-size: 16px;
  color: white
  font-weight: normal;
  margin-top:20px
}
.input-wrapper {
  &.white {
    background:transparent
    padding: 0px;
    .b-contain span {
      font-size: 16px!important;
      color: #1D1703
      font-weight: bold;
    }
  }
  .b-contain span {
    margin-left:7px
  }
  &:not(:first-child) {
    margin-top:24px
  }
  padding: 12px 16px;
  background: #F4ECEC;
  border-radius: 2px;
  width:100%
  .input-bg-color {
    
  }
  .input-title {
    font-weight: bold;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #00305D;
  }
  select {
    appearance:auto
  }
  input, select {
    width: 100%
    border: none
    background:transparent
    margin:8px 0 0 0
    padding:0
    height:auto
    box-shadow:none
    font-size: 18px;
    line-height: 100%;
    color: #000000;
  }
  input[type=text], textarea {
    width: 100%
    border: none
    background:transparent
    margin:8px 0 0 0
    padding:0
    height:auto
    box-shadow:none
    font-size: 18px;
    line-height: 100%;
    color: #000000;
  }
  input[type=checkbox] {
    width: 30px;
    vertical-align: text-bottom;
    height: 20px;
  }
  textarea {
    min-height:100px
  }
}
.error-msg {
  font-size:12px
  text-align: right;
  color: #ca1919;
}
.error-msg-on-blue {
  font-size:12px
  text-align: right;
  color: #cd8016;
}
.button {
  margin:0
}
.full-width {
  width:100%
}
.btn-container {
  margin-top:48px
}
.share-btn-container {
  margin-top:48px
}
.btn-pen-icon {
  width:20px
  margin-left:18px
}
.btn-share-icon {
  width:24px
  margin-left:16px
}
.loader2 {
  position:relative
  height:60px
}


.b-contain *, .b-contain *::before, .b-contain *::after {
	box-sizing: content-box !important;
}

.b-contain input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.b-contain span {
	line-height: 1.54;
	font-size: 1rem;
	font-family: inherit;
}

.b-contain {
	display: table;
	position: relative;
	padding-left: 1.8rem;
	cursor: pointer;
	margin-bottom: .5rem;
}

.b-contain input[type="checkbox"] ~ .b-input {
	position: absolute;
	top: 0;
	left: 0;
	height: 1.25rem;
	width: 1.25rem;
	background: rgba(255, 255, 255, 1);
	transition: background 250ms;
	border: 1px solid rgba(184, 194, 204, 1);
	border-radius: 0.125rem;
}

.b-contain input[type="radio"] ~ .b-input {
	position: absolute;
	top: 0;
	left: 0;
	height: 1.25rem;
	width: 1.25rem;
	background: rgba(255, 255, 255, 1);
	transition: background 250ms;
	border: 1px solid rgba(184, 194, 204, 1);
	border-radius: 2.0rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: .45rem;
	top: .18rem;
	width: .25rem;
	height: .6rem;
	border: solid rgba(255, 255, 255, 1);
	border-width: 0 2px 2px 0;
	transition: background 250ms;
	transform: rotate(45deg);
}

.b-contain input[type="radio"] ~ .b-input::after {
	content: '';
	position: absolute;
	display: none;
	left: .25rem;
	top: .25rem;
	width: .75rem;
	height: .75rem;
	border-radius: 2.0rem;
	background: rgba(255, 255, 255, 1);
	transition: background 250ms;
}

.b-contain input:disabled ~ .b-input::after {
	border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
	display: block;
}

.b-contain:hover input ~ .b-input,
.b-contain input:focus ~ .b-input {
	background: rgb(231, 238, 243);
}

.b-contain input:focus ~ .b-input {
	box-shadow: 0 0 0 0px rgba(52,144,220,0.5);
}

.b-contain input:checked ~ .b-input {
	background: rgba(0, 48, 93, 1);
	border-color: rgba(0, 48, 93, 1);
}

.b-contain input[type="checkbox"]:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: rgba(184, 194, 204, 1);
	opacity: 0.6;
	cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input {
	background: rgba(241, 245, 248, 1);
	border-color: rgba(184, 194, 204, 1);
	opacity: 0.6;
	cursor: not-allowed;
}

.b-contain input[type="radio"]:disabled ~ .b-input::after {
	background: rgba(135, 149, 161, 1);
}

.b-contain input:checked:focus ~ .b-input, .b-contain:hover input:not([disabled]):checked ~ .b-input {
	background: rgba(0, 48, 93, 1);
	border-color: rgba(0, 48, 93, 1);
}

.b-contain .b-input::before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 3rem;
	height: 3rem;
	margin-left: -0.85rem;
	margin-top: -0.85rem;
	background: rgba(0, 48, 93, 1);
	border-radius: 2rem;
	opacity: .6;
	z-index: 99999;
	transform: scale(0);
}

@keyframes b-ripple {
	0% {
		transform: scale(0);
	}

	20% {
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
  	}
}

@keyframes b-ripple-duplicate {
	0% {
		transform: scale(0);
	}

	30% {
		transform: scale(1);
	}

	60% {
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(1);
  	}
}

.b-contain input + .b-input::before {
	animation: b-ripple 250ms ease-out;
}

.b-contain input:checked + .b-input::before {
	animation-name: b-ripple-duplicate;
}

.b-contain .b-input::before {
	visibility: hidden;
}

.b-contain input:focus + .b-input::before {
	visibility: visible;
}

.b-contain:first-child .b-input::before {
	visibility: hidden;
}


@media screen and (max-width: 640px) {
  .component-wrapper {
    background:white
    //padding: 56px 32px
    margin:0px -9px
  }
  .form {
    background:white
    padding: 56px 32px
  }
  .form-subtitle {
    margin-top:40px
    font-size: 18px;
  }
  .form-elements {
    margin-top:24px
  }
  .form-title {
    font-size: 22px;
  }
  .form-text {
    font-size: 18px;
    margin-top:16px
  }
}
</style>
