<template>
  <div class="grid-x grid-margin-x header align-middle align-justify" :class="{'header-scrolled': state.siteScrolled}">
    <div v-show="ui.showMenu" class="mob-menu" ref="mobmenu">
      <img src="@/assets/close-icon.png" class="close-icon" @click="ui.showMenu=false" />
      <div class="grid-y grid-margin-y align-center align-middle">
        <!--div @click="$scrollTo('#faq');ui.showMenu=false" class="cell shrink mob-menu-cell">
          Beschreibung
        </div-->
        <div  @click="$scrollTo('#recipients');ui.showMenu=false" class="cell shrink mob-menu-cell">
          Empfänger
        </div>
        <div @click="$scrollTo('#form');ui.showMenu=false" class="cell shrink mob-menu-cell">
          Petition
        </div>
        <!--div @click="$scrollTo('#statements');ui.showMenu=false" class="cell shrink mob-menu-cell">
          Unterstützer
        </div-->
        <div @click="$scrollTo('#news');ui.showMenu=false" class="cell shrink mob-menu-cell">
          News
        </div>
        <div class="cell shrink mob-menu-cell">
          <div class="button afw-btn" @click="$scrollTo('#form');ui.showMenu=false">Jetzt Petition zeichnen<img class="btn-pen-icon" src="@/assets/icon_pen.png" /></div>
        </div>
      </div>
    </div>
    <div class="cell shrink">
      <img class="logo-img" src="@/assets/logo.png" />
    </div>
    <div class="cell auto grow show-for-small-only text-right">
      <img class="menu-icon-header" src="@/assets/menu_icon.png" @click="ui.showMenu=true" />
    </div>
    <div class="cell auto menu-links align-self-middle justify-right hide-for-small-only">
      <div class="grid-x grid-margin-x align-right align-middle">
        <!--div @click="$scrollTo('#faq')" class="cell shrink menu-cell">
          Beschreibung
        </div-->
        <div @click="$scrollTo('#recipients')" class="cell shrink menu-cell">
          Empfänger
        </div>
        <div @click="$scrollTo('#form')" class="cell shrink menu-cell">
          Petition
        </div>
        <!--div @click="$scrollTo('#statements')" class="cell shrink menu-cell">
          Unterstützer
        </div-->
        <div @click="$scrollTo('#news')" class="cell shrink menu-cell">
          News
        </div>
        <transition name="normalfade">
          <div class="cell shrink show-for-large" v-show="state.siteScrolled">
            
              <div class="button afw-btn margin-bottom-0 nowrap" @click="$scrollTo('#form')">Jetzt mitmachen</div>
            
          </div>
        </transition>
      </div>
    </div>
    <!--div class="cell auto button-cell text-right align-self-right">
      <div class="button afw-btn margin-bottom-0">Jetzt mitmachen</div>
    </div-->
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  mounted() {
    console.log(this.$refs.mobmenu)
    document.getElementById('app').append(this.$refs.mobmenu)
  },
  data() { return {
    state: this.$store.state,
    ui: {
      showMenu: false
    }
  }},
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
.btn-pen-icon {
  width:20px
  margin-left:18px
}
.mob-menu {
  background:rgba(255,255,255,1)
  position:fixed
  width:100%
  height:100%
  z-index:100
  top:0px
  left:0px
  padding-top:30%
  .mob-menu-cell {
    margin-bottom:40px
    text-align:center
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0.05em;
    color: #1D1703;
  }
  .close-icon {
    width:28px
    position:fixed
    top:48px
    right:48px
    z-index:105
  }
}
.nowrap {
  white-space:nowrap
}
.header-fixed {
  
}
.afw-btn {
  transition: all 2s ease
}
.header {
  max-width:1440px
  z-index:90
  height:115px
  position:fixed
  background: rgba(255,255,255,0.8)
  width:100%
  top:0px
  left: 0;
  right: 0;
  margin: 0 auto;
  padding:0 60px
  padding-bottom:15px
  transition: all 0.3s ease
  backdrop-filter: blur(8px);
}
.header-scrolled {
  //box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.5)
  box-shadow: 0px 36px 35px -38px rgba(0,0,0,0.5)
  height:90px
}
.logo-img {
  width:249px
}
.menu-cell {
  cursor:pointer
  text-align:right
  padding 0 10px
}
.menu-links {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  /* identical to box height, or 16px */

  letter-spacing: 0.05em;

  /* Neutral/Darkest */

  color: #1D1703;
}
@media screen and (max-width: 750px) {
  .logo-img {
    width:200px
  }
  .header {
    padding: 0 30px
  }
}
@media screen and (max-width: 640px) {
  .logo-img {
    max-width:60%
  }
  .header {
    padding: 0 30px
    .menu-icon-header {
      width:38px
    }
  }
}
</style>
