import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import Axios from 'axios'
import 'foundation-sites/dist/css/foundation.min.css'
import 'foundation-sites/dist/css/foundation-prototype.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)
import VueCarousel from 'vue-carousel'
Vue.use(VueCarousel)
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)
import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing)
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'
Vue.component('Modal', VueModal)

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo, {
  container: "body",
  duration: 400,
  easing: "ease",
  offset: window.innerWidth > 991 ? -100 : -75,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

import VueNumber from 'vue-number-animation'
Vue.use(VueNumber)

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2)

import Storage from 'vue-ls'
const lsoptions = {
  namespace: 'vuejs__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
}
Vue.use(Storage, lsoptions)

Vue.config.productionTip = false

Vue.prototype.$sleep = function(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

Vue.prototype.$store = store
Vue.prototype.$http = Axios

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
