<template>
  <div class="grid-container fluid main-container-padding">
    <Modal v-model="state.modals.shareModal" :title="'Petition teilen'">
      <template v-slot:titlebar>
        <div class="vm-titlebar">
          <h3 class="vm-title">
            Petition teilen
          </h3>
          <button
            type="button"
            class="vm-btn-close"
            @click.prevent="state.modals.shareModal = false"
          ></button>
        </div>
      </template>
      <slot name="content">
        <div class="shareModal">
          <ShareNetwork
            v-for="network in state.sharing.networks"
            :network="network.network"
            :key="network.network"
            :style="{backgroundColor: '#FFFFFF', color: '#000000'}"
            :url="state.sharing.sharingData.url"
            :title="state.sharing.sharingData.title"
            :description="state.sharing.sharingData.description"
            :quote="state.sharing.sharingData.quote"
            :hashtags="state.sharing.sharingData.hashtags"
            :twitterUser="state.sharing.sharingData.twitterUser"
          >
            <i :class="network.icon"></i>
            <span>{{ network.name }}</span>
          </ShareNetwork>
        </div>
      </slot>
    </Modal>
    <transition name="totopanim">
      <div v-if="state.siteScrolled" class="floating-to-top" @click="$scrollTo('body')">
        <!--div class="button secondary shadow">
          <strong>&uarr;</strong>
        </div-->
        <a href="javascript:void(0)" class="BUTTON_JJH"><img width="16px" src="@/assets/chevron_up.png"></a>
      </div>
    </transition>
    <div class="grid-x header-fixed">
      <div class="cell small-12">
        <afwHeader />
      </div>
    </div>
    <div class="grid-x content-bg">
      <div class="cell small-12">
        <Thankyou v-if="signingConfirmed" style="margin-bottom:125px" />
        <hero />
        <Progress class="progress-margin" />
        <Supporters class="supporters-margin" />
        <Articles class="articles-margin" />
        <Recipients id="recipients" class="recipients-margin" style="margin-top:125px" />
        <Faq id="faq" class="supporters-margin" style="margin-top:125px" />
        <Testimonials class="testimonials-margin" />
        <SignPetition id="form" class="signpetition-margin" />
        <Statements id="statements" class="statements-margin"/>
      </div>
    </div>
    <div class="grid-x footer">
      <News id="news" />
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import afwHeader from '@/views/header.vue'
import Thankyou from '@/views/thankyouSigning.vue'
import Hero from '@/views/01_hero.vue'
import Progress from '@/views/progress.vue'
import Supporters from '@/views/supporters.vue'
import Articles from '@/views/articles.vue'
import Recipients from '@/views/recipients.vue'
import Faq from '@/views/info_faq.vue'
import Testimonials from '@/views/testimonials.vue'
import SignPetition from '@/views/signPetition.vue'
import Statements from '@/views/statements.vue'
import News from '@/views/news.vue'
import Footer from '@/views/footer.vue'

export default {
  name: 'Home',
  components: {
    afwHeader,
    Hero,
    Progress,
    Supporters,
    Articles,
    Recipients,
    Faq,
    Testimonials,
    SignPetition,
    Statements,
    News,
    Footer,
    Thankyou
  },
  mounted() {
    console.log(this.$route)
    window.onscroll = () => {
      this.checkScroll()
    }
    this.checkParams()
    this.$store.setShareNetworks()
  },
  data() { return {
    state: this.$store.state,
    signingConfirmed: false
  }},
  methods: {
    checkScroll() {
      if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
        this.state.siteScrolled = true
      } else {
        this.state.siteScrolled = false
      }
    },
    checkParams() {
      if (this.$route.query.action == 'emailconfirm') {
        let type = this.$route.query.type
        let msg = this.$route.query.msg
        let title
        if (type == 'success') {
          this.signingConfirmed = true
          title = "Super!"
        } else {
          msg = 'Es ist ein Fehler aufgetreten. Die Rückmeldung vom Server lautet: ' + msg
          title = "Fehler"
           this.$swal.fire(
            title,
            msg,
            type
          )
        }
        this.$router.push(this.$route.path)
      }
    }
  }
}
</script>

<style lang="stylus">
.shareModal {
  font-family: Roboto;
  a[class^="share-network-"] .fah {
    background-color: transparent
    padding-right:2px!important
  }
  a[class^="share-network-"] {
    margin: 0 10px 0px 0
  }
}
.vm-title {
  font-family: Roboto;
}
.vm-titlebar {
  padding:20px 30px!important
  border-bottom:none!important
  padding-bottom:0!important
  overflow:hidden!important
}

.vm-btn-close {
  outline:none
  -webkit-tap-highlight-color: transparent;
}
.switch {
  margin-bottom: 0px!important
}
.share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
  }
  a[class^="share-network-"] {
    flex: none;
    color: #FFFFFF;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: inline-block;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
  }
  a[class^="share-network-"] .fah {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    flex: 0 1 auto;
  }
  a[class^="share-network-"] span {
    padding: 0 10px;
    flex: 1 1 0%;
    font-weight: 500;
  }
  .shareModal {
    padding-top:15px
    text-align:center
  }
</style>
<style lang="stylus" scoped>
.floating-to-top {
  position:fixed
  bottom:20px
  right:20px
  z-index:99
  .button {
    margin:0
    font-size:20px
    border-radius:50%
    line-height:1
  }
}
.BUTTON_JJH {
   background: #86BC26;
   //background-image: linear-gradient(to bottom, #E3E3E3, #B3B3B3);
   //border-radius: 57px;
   height: 56px;
   color: #1D1821;
   font-family: Arial;
   width: 56px;
   font-size: 20px;
   font-weight: 700;
   padding: 0;
   //box-shadow: 0 0 7px 0 #4E4E4E;
   text-shadow: 1px 1px 15px #000000;
   text-decoration: none;
   display: inline-block;
   cursor: pointer;
   text-align: center;
   -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* add code below to also center horizontally */
  align-items: center;
}

.BUTTON_JJH:hover {
   border: inset #FFFFFF 0;
   text-decoration: none;
}
.main-container-padding {
  padding-left 32px!important
  padding-right:32px!important
}
.content-bg {
  margin-top:130px
  background: #F6F6F6
  padding-left:95px
  padding-right:95px
  padding-top 100px
  padding-bottom:100px
}
.error-msg {
  margin-top: -15px;
  text-align: right;
  color: #ca1919;
}

.progress-margin {
  margin-top:90px
}

.supporters-margin {
  margin-top:95px
}

.articles-margin {
  margin-top:105px
}

.recipients-margin {
  margin-top:125px
}

.testimonials-margin {
  margin-top:125px
}

.signpetition-margin {
  margin-top:125px
}

.statements-margin {
  margin-top:125px
  //margin-top:0px
}

@media screen and (max-width: 640px) {
  .main-container-padding {
    padding-left:0px!important
    padding-right:0px!important
  }
  .content-bg {
    margin-top:130px
    background: #F6F6F6
    padding-left:25px
    padding-right:25px
    padding-top 50px
    padding-bottom:50px
  }
  .progress-margin {
    margin-top:50px
  }
  .supporters-margin {
    margin-top:64px
  }
  .articles-margin {
    margin-top:64px
  }
  .recipients-margin {
    margin-top:70px
  }
  .testimonials-margin {
    margin-top:0px
  }
  .signpetition-margin {
    margin-top:80px
  }
  .statements-margin {
    margin-top:104px
  }
}
</style>
