<template>
  <div class="component-wrapper">
    <div class="grid-x grid-margin-x align-spaced article" v-for="article of articles" :key="article.id">
      <div class="cell small-12 medium-12 large-6">
        <div class="grid-x article-content">
          <div class="cell medium-12 large-10">
            <div class="article-title">
              {{article.title}}
            </div>
            <div class="article-text" v-html="article.text">
            </div>
          </div>
        </div>
      </div>
      <div class="cell small-12 medium-12 large-6 align-self-middle article-image">
        <img :src="state.apiUrl + '/assets/' + article.image">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Supporters',
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    articles: [
    ]
  }},
  mounted() {
    this.getData()
  },
  computed: {
    
  },
  methods:{
    async getData() {
      try {
        let result = await this.$http.get(this.state.apiUrl + '/items/petition_articles?filter={"status":{"_eq":"published"}}')
        this.articles = result.data.data
        console.log(this.articles)
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.component-wrapper {
}
.article-title {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  color: #1D1703;
}
.article-text {
  margin-top:35px
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #1D1703;
  ::v-deep table {
    background-color: transparent!important;
    border: none!important;
    tbody {
      background-color: transparent!important;
      border: none!important;
      tr {
        background-color: transparent!important;
        border: none!important;
        td {
          background-color: transparent!important;
          border: none!important;
        }
      }
    }
  }
}
.article {
  &:not(:first-child) { 
    margin-top:50px
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
    .article-content {
      justify-content: right
    }
  }
}

@media screen and (max-width: 640px) {
  .article-title {
    font-size: 22px;
  }
  .article-text {
    margin-top:32px
  }
  .article-image {
    margin-top:16px
  }
  .article {
    &:not(:first-child) { 
      margin-top:75px
    }
  }
}
</style>
