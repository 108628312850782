<template>
  <div class="grid-x grid-margin-x align-justify">
    <div class="cell medium-6 large-6">
      <div class="text-container">
        <h1>Vielen Dank für Ihre Unterstützung!</h1>
        <div class="text-content">
          Empfehlen Sie diese Petition weiter:
        </div>
        <div class="button-container grid-x grid-margin-x">
          <div class="cell shrink">
            <div class="button afw-btn" @click="state.modals.shareModal = true">Petition teilen<img @click="state.modals.shareModal = true" class="btn-share-icon" src="@/assets/share_icon.png" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cell medium-5 large-5">
      <div class="right-card">
        <div class="title">
          “Emotionales Zitat eines Branchenexperten”
        </div>
        <div class="text">
          Mario Mustermann, AfW Mitglied und Makler
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Hero',
  components: {
  },
  mounted() {
  },
  data() { return {
    state: this.$store.state
  }},
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
  .right-card {
    box-shadow: 32px 32px 64px rgba(0, 0, 0, 0.1);
    background:white
    padding:48px 32px
    display: flex;
    height:100%
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-family: Playfair Display;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 130%;
      text-align: center;
      color: #00305D;
    }
    .text {
      margin-top:24px
      font-weight: 500;
      font-size: 16px;
      line-height: 160%;
      text-align: center;
      color: #1D1703;
    }
  }
  .text-container {
    //padding: 0px 64px 64px 64px
    border:0px solid black
  }
  .text-content {
    margin-top:45px
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #777777;
  }
  .button-container {
    margin-top:45px
    .btn-pen-icon {
      width:20px
      margin-left:18px
    }
    .btn-arrow-icon {
      width:12px
      margin-left:18px
    }
  }
  .btn-share-icon {
    width:24px
    margin-left:16px
  }
</style>
