<template>
  <div>
    <div class="">
      <img :src="state.apiUrl + '/assets/' + article.image">
    </div>
    <div class="article-title flex-container flex-dir-row grid-margin-x">
      <div class="accordeon-icon cell flex-child-shrink" v-if="state.isMobile">
        <img src="@/assets/plus.png" v-if="!showText" @click="showText = true">
        <img src="@/assets/minus.png" v-else @click="showText = false">
      </div>
      <div class="cell flex-child-auto">{{article.title}}</div>
    </div>
    <transition name="akkordeon2" v-on:before-enter="beforeEnter" v-on:enter="enter"
      v-on:before-leave="beforeLeave" v-on:leave="leave">
      <div class="article-text" v-if="showText" v-html="article.text">
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'RecipientsArticle',
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    showText: true
  }},
  props: {
    article: Object
  },
  mounted() {
    if (this.state.isMobile) this.showText = false
  },
  computed: {
    
  },
  methods:{
    beforeEnter: function(el) {
      el.style.height = '0';
    },
    enter: function(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    beforeLeave: function(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave: function(el) {
      el.style.height = '0';
    }
  }
}
</script>

<style scoped lang="stylus">
.article-title {
  margin-top:30px
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  color: #1D1703;
}
.article-text {
  margin-top:10px
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #1D1703;
  transition: 0.3s ease
  overflow:hidden
}
.accordeon-icon {
  img {
    width:15px
    vertical-align: unset
  }
}

@media screen and (max-width: 640px) {
  .article-title {
    margin-top:24px
    font-weight: bold;
    font-size: 20px;
    line-height: 130%;
    color: #1D1703;
  }
}
</style>
