<template>
  <div class="component-wrapper">
    <div class="article-title">
      Aktuelle Statements
    </div>
    <div class="grid-x grid-margin-x grid-margin-y align-stretch statement-card-container">
      <div class="fade-out-overlay"></div>
      <div v-for="statement of compStatements" :key="statement._id" class="cell statement-card large-4 medium-6 small-12 text-center">
        <div class="statement-text">
          {{statement.statement}}
        </div>
        <div class="statement-username">
          {{statement.username}}
        </div>
      </div>
      <div class="more-btn-container">
        <div class="button afw-btn" v-if="statementsShown < compNumberOfStatements" @click="statementsShown+=3">Mehr anzeigen</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Supporters',
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    statements: [],
    statementsShown: 6,
    fakeStatements: [
      {
        _id: 1,
        statement: 'Das ist die vielleicht wichtigste Petition, an der ich bisher teilgenommen habe. Wenn wir keinen Erfolg haben, kann ich meine Unabhängigkeit an den Nagel hängen.',
        username: 'Max'
      },
      {
        _id: 2,
        statement: 'Vom Ausgang hängt es ab, ob deutsche Bürger noch unabhängig beraten werden können!',
        username: 'Katrina'
      },
      {
        _id: 3,
        statement: 'Danke für euren Einsatz!',
        username: 'Jens'
      }
    ]
  }},
  mounted() {
    this.getData()
  },
  computed: {
    compStatements() {
      if (this.statements.length < 3) return this.fakeStatements
      return this.statements.slice(0, this.statementsShown)
    },
    compNumberOfStatements() {
      return this.statements.length
    }
  },
  methods:{
    async getData() {
      try {
        let result = await this.$http.get(this.state.apiUrl + '/items/signatory?meta=filter_count&filter={"public_allowed":{"_eq":"true"},"approved_for_public":{"_eq":"true"},"confirmed":{"_eq":"true"}}&fields=statement,username,date_created&sort=-date_created&limit=100')
        this.statements = result.data.data
        this.state.numberOfStatements = this.statements.length
        console.log(this.statements)
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.component-wrapper {
  padding-bottom: 125px
}
.statement-card-container {
  margin-top:64px
  position:relative
  //max-height:70000px
  overflow:hidden
}
.fade-out-overlay {
  position: absolute;
  bottom: 0px;
  //height: 250px;
  height:110px;
  z-index: 5;
  width: 100%;
  background: linear-gradient(
180deg, rgba(246,246,246,0) 0%, rgba(246,246,246,1) 54%, #f6f6f6 100%);
}
.statement-card {
  padding: 48px 24px
  background-color: white
}
.statement-text {
  font-family: Roboto;
  font-style: italic;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  text-align: center;
  color: #00305D;
}
.statement-username {
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  text-align: center;
  color: #777777;
  margin-top:10px
}
.article-title {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  color: #1D1703;
}
.more-btn-container {
  text-align:center
  display:inline-block
  position:absolute;
  bottom:50px
  z-index:6
  margin: 0 auto
  left:0
  right:0
}

@media screen and (max-width: 640px) {
  .component-wrapper {
    padding-bottom: 104px
  }
  .statement-card-container {
    margin-top:24px
  }
  .article-title {
    font-size: 22px;
  }
  .statement-text {
    font-size: 16px;
  }
}
</style>
