<template>
  <div class="component-wrapper">
    <div class="grid-x">
      <div class="cell medium-12 postion-relative">
        <div class="progress-number" :style="{left:compNumberPosition}" v-observe-visibility="visibilityChanged">
          <!--{{state.numberOfSigns}}-->
          <number
            ref="number1"
            :from="0"
            :to="state.numberOfSigns"
            :duration="2.4"
            :delay="0"
            :format="formatNumber"
            easing="Power1.easeOut"/>
        </div>
      </div>
    </div>
    <div class="progress-bar">
      <div class="progress-line" :class="{'gradient': compLineWidthRaw > 4}" :style="{width: compLineWidth}">
      </div>
    </div>
    <div class="progress-stats grid-x grid-padding-x">
      <div class="cell large-auto stat-cell first">
        <div class="">
          <div class="stats-title">
            Stand
          </div>
          <div class="stats-text">
            {{compCurDate}} <span class="nowrap">{{compCurTime}} Uhr</span>
          </div>
        </div>
      </div>
      <div class="cell large-auto stat-cell">
        <div class="stats-content">
          <div class="stats-title">
            Status
          </div>
          <div class="stats-text">
            noch {{compDaysLeft}} Tage
          </div>
        </div>
      </div>
      <div class="cell large-auto stat-cell">
        <div class="stats-content">
          <div class="stats-title">
            Petitionsziel
          </div>
          <div class="stats-text">
            {{formatNumber(this.state.neededNumberOfSigns)}}
          </div>
        </div>
      </div>
      <div class="cell large-auto stat-cell">
        <div class="stats-content">
          <div class="stats-title">
            Unterstützende
          </div>
          <div class="stats-text">
            {{formatNumber(state.numberOfSigns)}}
          </div>
        </div>
      </div>
      <div class="cell large-auto stat-cell last">
        <div class="stats-content">
          <div class="stats-title">
            Noch offen
          </div>
          <div class="stats-text">
            {{formatNumber(compNeededSigns)}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Stepper',
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    curDate: new Date(),
    endDate: new Date('2022-05-01'),
    amountOfSigns: 0
  }},
  mounted() {
    this.init()
  },
  computed: {
    compLineWidth() {
      return this.compLineWidthRaw + '%'
    },
    compLineWidthRaw() {
      let w = (100 / this.state.neededNumberOfSigns * this.state.numberOfSigns)
      if (w < 2) w = 2
      return w
    },
    compNumberPosition() {
      return (100 / this.state.neededNumberOfSigns * this.state.numberOfSigns) + '%'
    },
    compNeededSigns() {
      return this.state.neededNumberOfSigns - this.state.numberOfSigns
    },
    compCurDate() {
      return this.curDate.toLocaleDateString('de-DE')
    },
    compCurTime() {
      return this.curDate.toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'})
    },
    compDaysLeft() {
      let diff = Math.abs(this.endDate - this.curDate)
      let days = Math.round(diff/(1000 * 3600 * 24))
      return days
    }
  },
  methods:{
    async init() {
      this.getNumberOfSignatories()
      //this.$refs.number1.play()
    },
    async getNumberOfSignatories() {
      try {
        let result = await this.$http.get(this.state.apiUrl + '/items/signatory?meta=filter_count&filter={"confirmed":{"_eq":"true"}}&fields=[]&limit=1')
        this.amountOfSigns = result.data.meta.filter_count
        this.$store.setNumberOfSigns(result.data.meta.filter_count)
      } catch (err) {
        console.error(err)
      }
    },
    formatNumber(number) {
      return number.toLocaleString('de-DE', {maximumFractionDigits: 0})
    },
    async visibilityChanged(data) {
      if (data) {
        await this.$sleep(500)
        //this.$store.setNumberOfSigns(1962)
      } else {
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.nowrap {
  white-space:nowrap
}
.component-wrapper {
  background:white
  padding: 64px 100px
  filter: drop-shadow(32px 32px 64px rgba(0, 0, 0, 0.1))
}
.progress-number {
  display:inline-block
  font-size: 48px;
  font-weight:bold
  line-height: 130%;
  color: #00305D;
  position:relative
  transition: all 2.4s ease
}
.progress-bar {
  margin-top:10px
  width:100%
  height:16px
  background: #F6F6F6;
  border: 0.5px solid #E0E0E0;
  border-radius: 20px;
  .progress-line {
    border-radius: 20px;
    height:16px
    transition: all 2.4s ease
    background: #86BC26
    &.gradient {
      background: linear-gradient(270deg, #00305D 0%, #86BC26 50%);
    }
  }
}
.stat-cell {
  text-align:center
  margin-top:45px
  border-right: 1px solid #E0E0E0;
  &.last {
    border-right:none
  }
  &.first {
    flex-grow: 1.2;
    padding-left: 0px
  }
}
.stats-content {
  display:inline-block
}
.stats-title {
  font-size: 16px;
  line-height: 160%;
  color:#777777
  text-align:left
}
.stats-text {
  margin-top:5px
  font-weight: bold;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.05em;
  color: #00305D;
  text-align:left
}
@media screen and (max-width: 1024px) {
  .progress-stats {
    margin-top:32px
  }
  .stat-cell {
    text-align:left
    padding-top:16px
    padding-bottom:16px
    margin-top:0px
    border-right:unset
    border-bottom: 1px solid #E0E0E0;
    padding-left: 0px!important
    &.last {
      border-bottom:none
    }
  }
}
@media screen and (max-width: 640px) {
  .component-wrapper {
    background:white
    padding: 48px 32px
    filter: drop-shadow(32px 32px 64px rgba(0, 0, 0, 0.1))
  }
  .progress-number {
    font-size: 32px;
  }
  .progress-stats {
    margin-top:32px
  }
  .stat-cell {
    text-align:left
    padding-top:16px
    padding-bottom:16px
    margin-top:0px
    border-right:unset
    border-bottom: 1px solid #E0E0E0;
    padding-left: 0px!important
    &.last {
      border-bottom:none
    }
  }
}
</style>
