<template>
  <div class="component-wrapper">
    <div class="sup-title">
      Unsere Unterstützer
    </div>
    <div style="position:relative" class="grid-container">
      <div class="image-overlay"></div>
      <carousel
      ref="carousel"
      class="images grid-x grid-margin-x align-middle images align-spaced"
      :perPage="state.isMobile ? 2 : 3"
      :autoplay="true"
      :centerMode="true"
      :loop="true"
      :speed="30000"
      :autoplayHoverPause="false"
      :autoplayTimeout="1000"
      :easing="'linear'"
      :navigationEnabled="false"
      :paginationEnabled="false">
        <slide v-for="image of images" :key="'supp'+image.id" class="cell text-center image-slide">
          <img :src="image.url" />
        </slide>
      </carousel>
    </div>
    <!--
    <div class="grid-x grid-margin-x grid-margin-y align-middle images align-spaced">
      <div v-for="image of images" :key="image.id" class="cell large-2 medium-3 small-6 text-center">
        <img :src="image.url" />
      </div>
    </div>
  -->
  </div>
</template>

<script>
export default {
  name: 'Supporters',
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    images: [
      {
        id:1,
        url: require('@/assets/bvk.png')
      },
      {
        id:2,
        url: require('@/assets/bdvm.png')
      },
      {
        id:3,
        url: require('@/assets/fw.png')
      },
      {
        id:4,
        url: require('@/assets/ac.png')
      },
      {
        id:5,
        url: require('@/assets/pf.png')
      },
      {
        id:6,
        url: require('@/assets/ff.png')
      }
    ]
  }},
  async mounted() {
    await this.$sleep(200)
    console.log(this.$refs.carousel)
    //this.$refs.carousel.startAutoplay()
    this.$refs.carousel.autoplayTimeout = 30000
  },
  computed: {
    
  },
  methods:{
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.component-wrapper {
}
.image-overlay {
  background: linear-gradient(90deg, rgba(246,246,246,1) 0%, rgba(250,250,250,0) 25%, rgba(255,255,255,0) 75%, rgba(246,246,246,1) 100%);
  position:absolute;
  z-index:5
  width:100%
  height:100%
  top:0
  left:0
}
.sup-title {
  font-size: 18px;
  line-height: 150%;
  color: #00305D;
  font-weight: bold;
}
.images {
  margin-top:25px
  img {
    max-height:95px
  }
}
.image-slide {
  padding:0 20px
}
</style>
