<template>
  <div class="component-wrapper">
    <carousel
    class="article"
    :perPage="1"
    :navigationEnabled="true"
    :paginationEnabled="false">
      <slide v-for="article of articles" :key="'testi'+article.id">
        <div class="grid-y">
          <div class="cell article">
            <div class="grid-x">
              <div class="cell medium-12 article-text">
                “{{article.text}}”
              </div>
            </div>
          </div>
          <div class="cell article-image" v-if="article.image">
            <img :src="state.apiUrl + '/assets/' + article.image">
          </div>
          <div class="cell bottom-line">
            <div class="grid-x">
              <div class="cell small-1">
                <!--img src="@/assets/green_arrow_left.png"-->
              </div>
              <div class="cell small-10 article-title">
                {{article.name}}
              </div>
              <div class="cell small-1">
                <!--img src="@/assets/green_arrow_right.png"-->
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
export default {
  name: 'Recipients',
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    articles: [
    ]
  }},
  mounted() {
    this.getData()
  },
  computed: {
    
  },
  methods:{
    async getData() {
      try {
        let result = await this.$http.get(this.state.apiUrl + '/items/petition_testimonials?filter={"status":{"_eq":"published"}}')
        this.articles = result.data.data
        console.log(this.articles)
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.component-wrapper {
  background:#F5EDED
  padding: 95px 105px
}
.article {
  ::v-deep .VueCarousel-navigation-button {
    color:#86BC26
  }
}
.article-image, .bottom-line {
  margin-top:32px
  text-align:center
}
.article-image {
  img {
    width:98px
  }
}
.bottom-line {
  img {
    width:16px
  }
}
.article-title {
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #1D1703;
}
.article-text {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  text-align: center;
  color: #00305D;
}
@media screen and (max-width: 640px) {
  .component-wrapper {
    padding: 64px 24px
    margin-left: -25px
    margin-right:-25px
  }
  .article-text {
    font-size: 22px;
  }
}
</style>
