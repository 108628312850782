<template>
  <div class="component-wrapper">
    <div class="grid-y">
      <div class="cell article" v-for="article of articles" :key="'info'+article.id">
        <faqPart :article="article" />
      </div>
    </div>
  </div>
</template>

<script>
import faqPart from '@/views/info_faq_part'

export default {
  name: 'FAQ',
  components: {
    faqPart
  },
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    articles: [
    ]
  }},
  mounted() {
    this.getData()
  },
  computed: {
    
  },
  methods:{
    async getData() {
      try {
        let result = await this.$http.get(this.state.apiUrl + '/items/petition_info_faq?filter={"status":{"_eq":"published"}}')
        this.articles = result.data.data
        console.log(this.articles)
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.component-wrapper {
  background:white
  padding: 125px 105px
}
.article {
  &:not(:first-child) {
    padding-top:48px
  }
  &:not(:last-child) {
    padding-bottom:48px
    border-bottom:1px solid #E0E0E0;
  }
}
.article-title {
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  color: #1D1703;
}
.article-text {
  ::v-deep p {
    margin-bottom:0px
  }
  font-size: 18px;
  line-height: 150%;
  color: #1D1703;
}

@media screen and (max-width: 640px) {
  .component-wrapper {
    padding: 64px 24px
    margin-left: -25px
    margin-right:-25px
  }
  .article {
    &:not(:first-child) {
      padding-top:24px
    }
    &:not(:last-child) {
      padding-bottom:24px
      border-bottom:1px solid #E0E0E0;
    }
  }
  .article-title {
    font-size: 20px;
  }
  .article-text {
    margin-top:24px
    font-size: 16px;
  }
}
</style>
