<template>
  <div class="grid-x grid-margin-x align-justify">
    <div class="cell small-12 medium-12 large-6">
      <div class="text-container">
        <h1>PETITION: Für ein freies Wahlrecht bei der Vergütung unabhängiger Beratung</h1>
        <div class="text-content">
          Votieren Sie gegen die Pläne der Rot-Grünen Regierungsmehrheit, eine unabhängige und anbieterneutrale Finanzberatung nur noch gegen Zahlung von Beratungshonoraren zuzulassen bzw. die Höhe der in Produkte einkalkulierten Beratungsvergütungen pauschal zu begrenzen. Stimmen Sie mit uns dafür, dass Verbraucher:innen und Berater:innen das gemeinsame Recht haben, die Art und den Umfang der Beratung - und die daraus folgende Höhe Vergütung - selbst zu vereinbaren.
        </div>
        <div class="button-container grid-x grid-margin-x">
          <div class="cell shrink">
            <div class="button afw-btn margin-bottom-0 nowrap" @click="$scrollTo('#form');ui.showMenu=false">Jetzt Petition zeichnen<img class="btn-pen-icon" src="@/assets/icon_pen.png" /></div>
          </div>
          <div class="cell auto hide-for-small-only">
            <div class="button afw-btn btn-white margin-bottom-0 nowrap" @click="state.modals.shareModal = true">Petition teilen<img @click="state.modals.shareModal = true" class="btn-share-icon" src="@/assets/share_icon_blue.png" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="cell medium-5 large-5 show-for-large">
      <img src="@/assets/bundestag2.jpg" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'Hero',
  components: {
  },
  mounted() {
    console.log("hero")
  },
  data() { return {
    state: this.$store.state
  }},
  methods: {
  }
}
</script>

<style lang="stylus" scoped>
  .img-container {
    margin-top:250px
  }
  .btn-white {
    color: #00305D!important
  }
  .text-container {
    //padding: 0px 64px 64px 64px
    border:0px solid black
  }
  .text-content {
    margin-top:45px
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #1D1703;
  }
  .button-container {
    margin-top:45px
    .btn-pen-icon {
      width:20px
      margin-left:18px
    }
    .btn-share-icon {
      width:22px
      margin-left:18px
    }
  }
  @media screen and (max-width: 640px) {
    .text-content {
      margin-top:32px
    }
    .button-container {
      margin-top:32px
    }
  }
</style>
