<template>
  <div class="component-wrapper">
    <div class="header-image">
      <img class="show-for-medium" src="@/assets/news.jpg">
      <img class="show-for-small-only" src="@/assets/news_mob.jpg">
      <div class="title-container">
        <div class="title">News</div>
        <div class="subtitle">Aktuelle Informationen zum Verlauf unserer Petition</div>
      </div>
    </div>
    <div class="grid-x grid-margin-x grid-padding-x align-center articles">
      <div v-for="article of articles" :key="article.id" class="cell article large-5 medium-6 small-12 text-center">
        <div class="category">
          {{article.category}}
        </div>
        <div class="title">
          {{article.title}}
        </div>
        <div class="text" v-html="article.text">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'News',
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    articles: []
  }},
  mounted() {
    this.getData()
  },
  computed: {
    
  },
  methods:{
    async getData() {
      try {
        let result = await this.$http.get(this.state.apiUrl + '/items/petition_news?filter={"status":{"_eq":"published"}}&limit=2')
        this.articles = result.data.data
        console.log(this.articles)
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.component-wrapper {
  background-color:white
  padding-bottom:128px
}
.header-image {
  position:relative
}
.title-container {
  position:absolute
  background:white
  top:80px
  left:120px
  padding:48px
  .title {
    text-align:left
    font-family: Playfair Display;
    font-weight: bold;
    font-size: 40px;
    line-height: 130%;
    color: #1D1703;
  }
  .subtitle {
    margin-top:16px
    font-size: 18px;
    line-height: 150%;
    color: #1D1703;
  }
}
.articles {
  margin-top:80px
  .category {
    text-align:left
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    color: #777777;
  }
  .title {
    text-align:left
    margin-top:5px
    font-weight: bold;
    font-size: 24px;
    line-height: 130%;
    color: #1D1703;
  }
  .text {
    text-align:left
    margin-top:16px
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    color: #1D1703;
  }
}

@media screen and (max-width: 1024px) {
  .title-container {
    position:absolute
    background:white
    top:40px
    left:24px
    right:24px
    margin: 0 auto
    padding:32px
    .title {
      font-size: 22px;
    }
    .subtitle {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 640px) {
  .component-wrapper  {
    //margin-left:-25px
    //margin-right:-25px
    padding-bottom:64px
  }
  .title-container {
    position:absolute
    background:white
    top:40px
    left:24px
    right:24px
    margin: 0 auto
    padding:32px
    .title {
      font-size: 22px;
    }
    .subtitle {
      font-size: 16px;
    }
  }
  .articles {
    margin-top:48px
    padding-left:16px
    padding-right:16px
    .article {
      &:not(:first-child) {
        margin-top:32px
      }
      &:not(:last-child) {
        padding-bottom:16px
        border-bottom:1px solid #E0E0E0
      }
    }
  }
  
}
</style>
