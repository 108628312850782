import Vue from 'vue'

const state = new Vue({
  data() {
    return {
      state: {
        isMobile: window.innerWidth <= 640 ? true : false,
        apiUrl: process.env.VUE_APP_API_URL,
        siteScrolled: false,
        $route: null,
        hasSigned:false,
        numberOfSigns: 0,
        numberOfStatements: 0,
        neededNumberOfSigns: 5000,
        modals: {
          shareModal: false
        },
        sharing: {
          sharingData: {
            url: 'https://afw-petition.birkenbeul.com',
            title: 'PETITION: Für ein freies Wahlrecht bei der Vergütung unabhängiger Beratung',
            description: 'Votieren Sie gegen die Pläne der Rot-Grünen Regierungsmehrheit, eine unabhängige und anbieterneutrale Finanzberatung nur noch gegen Zahlung von Beratungshonoraren zuzulassen bzw. die Höhe der in Produkte einkalkulierten Beratungsvergütungen pauschal zu begrenzen. Stimmen Sie mit uns dafür, dass Verbraucher:innen und Berater:innen das gemeinsame Recht haben, die Art und den Umfang der Beratung - und die daraus folgende Höhe Vergütung - selbst zu vereinbaren.',
            quote: '',
            hashtags: 'freiheit,afw,petition,beratung',
            twitterUser: 'AfW_Verband'
          },
          networks: [
            { network: 'xing', name: 'Xing', icon: 'fab fah fa-lg fa-xing', color: '#026466' },
            { network: 'linkedin', name: 'LinkedIn', icon: 'fab fah fa-lg fa-linkedin', color: '#007bb5' },
            { network: 'whatsapp', name: 'Whatsapp', icon: 'fab fah fa-lg fa-whatsapp', color: '#25d366' },
            //{ network: 'telegram', name: 'Telegram', icon: 'fab fah fa-lg fa-telegram-plane', color: '#0088cc' },
            { network: 'twitter', name: 'Twitter', icon: 'fab fah fa-lg fa-twitter', color: '#1da1f2' },
            { network: 'facebook', name: 'Facebook', icon: 'fab fah fa-lg fa-facebook-f', color: '#1877f2' },
            //{ network: 'messenger', name: 'Messenger', icon: 'fab fah fa-lg fa-facebook-messenger', color: '#0084ff' },
            //{ network: 'skype', name: 'Skype', icon: 'fab fah fa-lg fa-skype', color: '#00aff0' },
            //{ network: 'baidu', name: 'Baidu', icon: 'fas fah fa-lg fa-paw', color: '#2529d8' },
            //{ network: 'buffer', name: 'Buffer', icon: 'fab fah fa-lg fa-buffer', color: '#323b43' },
            { network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333' },
            //{ network: 'evernote', name: 'Evernote', icon: 'fab fah fa-lg fa-evernote', color: '#2dbe60' },
            
            //{ network: 'flipboard', name: 'Flipboard', icon: 'fab fah fa-lg fa-flipboard', color: '#e12828' },
            //{ network: 'hackernews', name: 'HackerNews', icon: 'fab fah fa-lg fa-hacker-news', color: '#ff4000' },
            //{ network: 'instapaper', name: 'Instapaper', icon: 'fas fah fa-lg fa-italic', color: '#428bca' },
            //{ network: 'line', name: 'Line', icon: 'fab fah fa-lg fa-line', color: '#00c300' },
            
            
            //{ network: 'odnoklassniki', name: 'Odnoklassniki', icon: 'fab fah fa-lg fa-odnoklassniki', color: '#ed812b' },
            //{ network: 'pinterest', name: 'Pinterest', icon: 'fab fah fa-lg fa-pinterest', color: '#bd081c' },
            //{ network: 'pocket', name: 'Pocket', icon: 'fab fah fa-lg fa-get-pocket', color: '#ef4056' },
            //{ network: 'quora', name: 'Quora', icon: 'fab fah fa-lg fa-quora', color: '#a82400' },
            //{ network: 'reddit', name: 'Reddit', icon: 'fab fah fa-lg fa-reddit-alien', color: '#ff4500' },
            
            { network: 'sms', name: 'SMS', icon: 'far fah fa-lg fa-comment-dots', color: '#333333' },
            //{ network: 'stumbleupon', name: 'StumbleUpon', icon: 'fab fah fa-lg fa-stumbleupon', color: '#eb4924' },
            
            //{ network: 'tumblr', name: 'Tumblr', icon: 'fab fah fa-lg fa-tumblr', color: '#35465c' },
            
            //{ network: 'viber', name: 'Viber', icon: 'fab fah fa-lg fa-viber', color: '#59267c' },
            //{ network: 'vk', name: 'Vk', icon: 'fab fah fa-lg fa-vk', color: '#4a76a8' },
            //{ network: 'weibo', name: 'Weibo', icon: 'fab fah fa-lg fa-weibo', color: '#e9152d' },
            
            //{ network: 'wordpress', name: 'Wordpress', icon: 'fab fah fa-lg fa-wordpress', color: '#21759b' },
            
            //{ network: 'yammer', name: 'Yammer', icon: 'fab fah fa-lg fa-yammer', color: '#0072c6' },
          ]
        }
      }
    }
  },
  computed: {
  },
  mounted() {
    
  },
  methods: {
    setShareNetworks() {
      console.log("setsharenwtworks")
      if (this.state.isMobile) {
        this.state.sharing.networks = this.state.sharing.networks.filter((o) => { return o.network !== 'sms'})
      }
    },
    errorHandler(error) {
      if (!error) return ''
      if (error.response) {
        if (error.response.data.errors[0]) {
          return error.response.data.errors[0].message
        }
      } else return error.toString()
    },
    setNumberOfSigns(n) {
      this.state.numberOfSigns = n
    }
  }
})

export const store = state