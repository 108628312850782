<template>
  <div class="component-wrapper">
    <div class="title text-center">
      Empfänger der Petition
    </div>
    <div class="subtitle text-center">
      Die Petition richtet sich an
    </div>
    <div class="articles grid-x grid-margin-x align-spaced">
      <div class="cell article small-12 medium-4 large-4" v-for="article of articles" :key="article.id">
        <recipients-article :article="article" />
      </div>
    </div>
  </div>
</template>

<script>
import RecipientsArticle from '@/views/recipients_article'

export default {
  name: 'Recipients',
  components: {
    RecipientsArticle
  },
  props: {
    msg: String
  },
  data() { return {
    state: this.$store.state,
    articles: [
    ]
  }},
  mounted() {
    this.getData()
  },
  computed: {
    
  },
  methods:{
    async getData() {
      try {
        let result = await this.$http.get(this.state.apiUrl + '/items/petition_recipients?filter={"status":{"_eq":"published"}}')
        this.articles = result.data.data
        console.log(this.articles)
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.component-wrapper {
}
.title {
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  color: #1D1703;
}
.subtitle {
  font-size: 18px;
  line-height: 150%;
  color: #1D1703;
  margin-top:32px
}
.articles {
  margin-top:48px
}
.article-title {
  margin-top:30px
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  color: #1D1703;
}
.article-text {
  margin-top:10px
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%;
  color: #1D1703;
}

@media screen and (max-width: 640px) {
  .title {
    font-size: 22px;
  }
  .subtitle {
    font-size: 16px;
    margin-top:16px
  }
  .article-title {
    margin-top:24px
    font-weight: bold;
    font-size: 20px;
    line-height: 130%;
    color: #1D1703;
  }
  .articles {
    margin-top:0px
  }
  .article {
    margin-top:32px
  }
}
</style>
